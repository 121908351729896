<div class="container"  >

  <div *ngIf="album.Id !== 0" class="btn-group" role="group" style="margin-bottom: 10px;">
    <a routerLink="/albums" class="btn btn-sm btn-primary"><i class="fa fa-list"></i> List</a>
    <a [routerLink]="['/album',album.Id]" class="btn btn-sm btn-primary"><i class="fa fa-eye"></i> View</a>
    <a [href]="album.AmazonUrl" class="btn btn-sm btn-primary"><i class="fa fa-dollar"></i> Buy</a>
  </div>

  <div class="separator"></div>

  <error-display [error]="error"></error-display>

  <div class="row"  [ngClass]="{ 'hidden': !loaded}" [@slideIn]="'in'">

    <div class="col-sm-5">
      <form name="form1" action="#0" #form1="ngForm" novalidate>

        <div class="form-group">
          <label for="AlbumName">Album Name:</label>
          <input id="AlbumName" type="text" class="form-control input-sm"
                 placeholder="Album Name"
                 [focus]="true"
                 name="Title" required
                 [(ngModel)]="album.Title" autofocus />

        </div>


          <div class="form-group">
              <label for="ArtistName">Band Name:</label>
              <input id="ArtistName" type="text"
                     name="ArtistName"
                     class="form-control"
                     [(ngModel)]="album.Artist.ArtistName"
                     [typeahead]="searchResults"
                     [typeaheadAsync]="true"
                     typeaheadWaitMs="200"
                     (typeaheadLoading)="changeTypeaheadLoading($event)"

                     />
          </div>
<!--          [resultFormatter]="resultFormatBandListValue"-->
<!--          [inputFormatter]="inputFormatBandListValue"-->

          <div class="form-group">
          <label for="Description">Album Description:</label>
          <textarea class="form-control input-sm" id="Description"
                    placeholder="Album description or review"
                    required
                    [(ngModel)]="album.Description"
                    name="Description"
                    style="height: 115px"></textarea>

        </div>

        <div class="form-group">
          <div class="input-group">
              <span class="input-group-prepend" title="Image Url">
                  <span class="input-group-text">
                      <i class="fa fa-image fa-fw"></i>
                  </span>
              </span>
            <input type="text" class="form-control input-sm" id="ImageUrl"
                   required
                   name="ImageUrl"
                   placeholder="Image Url" [(ngModel)]="album.ImageUrl" />

          </div>
        </div>

        <div class="form-group">
          <div class="input-group">
              <span class="input-group-prepend" title="Url where to buy">
                  <span class="input-group-text">
                      <i class="fa fa-dollar fa-fw"></i>
                  </span>
              </span>
            <input type="text" class="form-control input-sm" id="AmazonUrl"
                   placeholder="Purchase Url"
                   name="AmazonUrl"
                   [(ngModel)]="album.AmazonUrl"/>
          </div>
        </div>

        <div class="form-group">
          <div class="input-group">
              <div class="input-group-prepend" title="Url where to buy">
                  <span class="input-group-text">
                      <i class="fa fa-spotify fa-fw"></i>
                  </span>
              </div>
            <input type="text" class="form-control input-sm" id="SpotifyUrl"
                   placeholder="Listen Url"
                   name="SpotifyUrl"
                   [(ngModel)]="album.SpotifyUrl" />
          </div>
        </div>

        <div class="form-group">
          <label for="YearReleased">Year released:</label>
          <input type="number" class="form-control input-sm" id="YearReleased"
                 required
                 name="Year"
                 placeholder="Year of album release"
                 [(ngModel)]="album.Year"/>
        </div>

        <hr/>

        <album-songlist [tracks]="album.Tracks" [allowEditing]="true"></album-songlist>

        <div >

          <button type="submit" (click)="saveAlbum(album)"
                  class="btn btn-success"
                  [disabled]="form1.invalid" accesskey="S">
            <i class="fa fa-check"></i> Save
          </button>

          <button [routerLink]="['/album',album.Id]" class="btn btn-secondary ml-2">
            <i class="fa  fa-remove"></i> Cancel
          </button>
        </div>

        <div class="separator"></div>

      </form>
    </div>

    <div class="col-sm-7">
      <h3>Preview</h3>
      <img [src]="album.ImageUrl" onerror="this.src=''" class="album-image-big"/>
      <div style="margin-top: 10px;">
        <h2 class="album-title-big">{{album.Title}}</h2>
        <div class="album-artist">by {{album.Artist.ArtistName}} {{(album.Year ? 'in ' + album.Year : '')}}
          - <a [href]="album.AmazonUrl" *ngIf="album.AmazonUrl"><i class="fa fa-dollar"></i> Buy on Amazon</a>
        </div>
        <div class="album-descript line-breaks" [innerHTML]="album.Description"></div>

        <hr/>

        <album-songlist [tracks]="album.Tracks"></album-songlist>

      </div>
    </div>


  </div>

</div>
