<ng-template #ModalEditorTemplate let-modal>
<form name="form1"
    action="javascript:{}" novalidate
    #form1="ngForm">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Artist</h4>
    <button type="button" class="close" aria-label="Close"
            (click)="close()"  >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

      <div class="modal-body">
         <div class="form-group">
            <label for="ArtistName">Artist Name:</label>
            <input name="ArtistName" id="ArtistName" type="text"
                   class="form-control input-sm"
                   [focus]="true"
                   placeholder="Artist Name" required
                   [(ngModel)]="artist.ArtistName"  #ArtistName/>
          </div>

          <div class="form-group">
            <label for="Description">Bio:</label>
            <textarea type="text" class="form-control input-sm"
                      name="Description" id="Description"
                      required
                      style="height: 110px;"
                      minlength="20"
                      placeholder="Band Bio"
                      [(ngModel)]="artist.Description"></textarea>
          </div>
          <div class="form-group">
            <label for="ImageUrl">Image Url:</label>
            <input type="text" class="form-control input-sm"
                   id="ImageUrl" name="ImageUrl"
                   placeholder="Image Url" [(ngModel)]="artist.ImageUrl" />
          </div>
          <div class="form-group">
            <label for="AmazonUrl">Amazon Url:</label>
            <input type="text" class="form-control input-sm"
                   name="AmazonUrl" id="AmazonUrl"
                   placeholder="Link to Artist Amazon Page" [(ngModel)]="artist.AmazonUrl" />
          </div>

      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">
      <i class="fa fa-remove"></i>
      Cancel
    </button>

    <button type="button" class="btn btn-primary"
                [disabled]="form1.pristine || form1.invalid" accesskey="S"
                (click)="saveArtist(artist)">
          <i class="fa fa-check"></i>
          Save changes
      </button>
  </div>
</form>
</ng-template>

