<div class="container">  
  <h2 class="page-header-text">
    <i class="fa fa-info-circle"></i>  About the AlbumViewer Sample
  </h2>

  <div class="well well-sm">
    <h4>ASP.NET Core API backend</h4>
    <p>
      This application runs an ASP.NET Core 2.0 API server with a
      JSON Service backend . 
    </p>
  </div>

  <div class="well well-sm">
    <h4>Angular Front End</h4>
    <p>
      The client interface uses Angular 7 to provide the front end logic and
      UI management features. There are album and artist services that
      talk to the backend interface and page level components and child
      components that handle individual view pages of the application.
    </p>
  </div>

  <div class="well well-sm">
    <h4>Mobile enabled based on modified Bootstrap 4.0 Interface</h4>
    <p>
      The base Bootstrap framework is used for the core UI features
      of the interface. The base template has been customized for
      this application.
    </p>
    <p>
      The application is mobile focused and uses custom resizing
      and toolbar relocation based on the screen/device size used.
      The application should be comfortably usable from phone up to full screen
      desktop applications.
    </p>
  </div>

  <hr />
  <a routerLink="/albums">Back to Application</a>

</div>
