<div *ngIf="allowEditing">
  <button (click)="addTrack(track)" accesskey="a"
          *ngIf="!isSongVisible" title="Add Song (alt-a)"
          class="btn btn-info btn-xs" style="margin-bottom: 10px;">
    <i class="fa fa-plus-circle"></i> Add Song
  </button>

  <div *ngIf="isSongVisible" class="well well-sm">

    <div class="row">
      <div class=" col-sm-9">
        <input class="form-control input-sm" id="songSongName"
               placeholder="Name of the song"
               name="songSongName"
               [(ngModel)]="track.SongName"/>

      </div>
      <div class="col-sm-3">
        <input class="form-control input-sm" id="Length"
               name="Length"
               [(ngModel)]="track.Length"
               placeholder="0:00"/>
      </div>
    </div>

    <button class="btn btn-success btn-xs"
            (click)="saveTrack(track)"
            [disabled]="!track.SongName"
            style="margin-top: 8px;" accesskey="">
      <i class="fa fa-plus-circle"></i> Save Track
    </button>
    <button class="btn btn-default btn-xs"
            (click)="cancelTrack()"
            style="margin-top: 8px;">
      <i class="fa fa-times"></i> Cancel
    </button>
  </div>
</div>

<style>
    @media(max-width: 700px) {
        #TrackTable td i.fa { display: none; }
    }
</style>
<table id="TrackTable" class="table table-striped table-sm">
    <tbody>
        <tr *ngFor="let track of tracks">            
            <td><i class="fa fa-music text-primary"></i>
                {{track.SongName}}</td>
            <td>{{track.Length}}</td>
            <td>
                <button *ngIf="allowEditing" (click)="removeTrack(track)"
                        class="btn btn-sm btn-danger">
                    <i class="fa fa-remove"></i> Remove
                </button>
            </td>
        </tr>
    </tbody>
</table>
