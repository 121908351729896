<app-header></app-header>

<div style=" padding: 1px;">

        <div id="MainView" >
            <router-outlet autoscroll="true"></router-outlet>
        </div>
        <app-footer></app-footer>

</div>

<footer>
  <hr/>
  &copy West Wind Technologies
</footer>
