<div  class="container">
  
  <error-display [error]="error"></error-display>

  <div class="list-group" style="margin-top: 20px;">
      <style>
          .list-group .fa { color: steelblue; font-size: 1.3em;padding-right: 15px;}
      </style>
    <a  href="javascript:{}" (click)="reloadData()" class="list-group-item list-group-item-action">
      <h4 class="list-group-item-heading">
        <i class="fa fa-fw fa-refresh"></i>
        Reset Data
      </h4>
      <p class="list-group-item-text">Reset data to original sample data.</p>
    </a>

    <a routerLink="/about" class="list-group-item list-group-item-action">
      <h4 class="list-group-item-heading">
        <i class="fa fa-fw fa-info-circle"></i>
        About Album Viewer
      </h4>
      <p>
        <small *ngIf="config.applicationStats.OsPlatform">Server Platform: {{config.applicationStats.OsPlatform}}</small><br/>
        <small *ngIf="config.applicationStats.AspDotnetVersion">ASP.NET Version: {{config.applicationStats.AspDotnetVersion}}</small><br/>
        <small *ngIf="config.applicationStats.AngularVersion">Angular Version: {{config.applicationStats.AngularVersion}}</small><br>
        <small *ngIf="config.applicationStats.DataMode">Data Mode: {{config.applicationStats.DataMode}}</small>
      </p>
    </a>

    <a routerLink="/" class="list-group-item list-group-item-action">
      <h4>
        <i class="fa fa-fw fa-home"></i>
        Back to Album List
      </h4>
    </a>
    
        <a href="https://github.com/RickStrahl/AlbumViewerVNext" class="list-group-item list-group-item-action">
        <h4>
          <i class="fa fa-fw fa-github"></i>
          GitHub 
        </h4>
      </a>
      
    <div class="list-group-item list-group-item-action">
      <h4><i class="fa fa-fw fa-gears"></i> Settings</h4>

      <div class="form-group">
        <label class="control-label" for="httpBaseUrl">Data Http Base URL:</label>
        <input type="text" class="form-control" id="httpBaseUrl"
               placeholder="Data Http BaseUrl" [(ngModel)]="config.urls.baseUrl">
      </div>
    </div>

    <a *ngIf="!user.isAuthenticated" routerLink="/login" class="list-group-item list-group-item-action">
      <h4 class="list-group-item-heading">
        <i class="fa fa-unlock-alt"></i>
        Sign in
      </h4>
    </a>
    <a *ngIf="user.isAuthenticated" routerLink="/logout" class="list-group-item list-group-item-action">
      <h4 class="list-group-item-heading">
        <i class="fa fa-lock"></i>
        Sign out
      </h4>
    </a>
  </div>

</div>
