<div class="container container-fluid slide-animation" [@slideIn]="'in'">

    <error-display [error]="error"></error-display>

    <a routerLink="/album/edit/0" class="btn btn-sm btn-success pull-right" (click)="addAlbum()"><i class="fa fa-plus"></i> Add Album</a>
    <div class="page-header-text">
        <i class="fa fa-list">
        </i>Album <span class="badge badge-danger badge-small">{{filteredAlbumList.length}}</span>
    </div>

    <div class="container" *ngIf="busy">
        <i class="fa fa-circle-o-notch fa-spin fa-5x"
           style="display: block; text-align: center; margin-top: 70px;"></i>
    </div>


    <a class="album" role="button" style="text-decoration: none; color: inherit"
         *ngFor="let album of filteredAlbumList"
         (click)="albumClick(album)" >

        <div class="album-overlay">
            <a routerLink="['/album/edit',{{album.Id}}]">
              <i class="fa fa-edit" title="Edit"></i>
            </a>
            &nbsp;
            <a (click)="deleteAlbum(album)">
              <i class="fa fa-remove" title="Delete"></i>
            </a>
        </div>

        <div>
            <img [src]="album.ImageUrl" class="album-image" />
            <div style="padding-left: 80px;">
                <div class="album-title">{{album.Title}}</div>
                <div class="album-artist">by <b>{{album.Artist.ArtistName}}</b> {{(album.Year ? 'in ' + album.Year : '')}}</div>
                <div class="album-descript">{{album.Description}}</div>
            </div>
        </div>
    </a>
</div>
